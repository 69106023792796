import { send } from "../index";

export function getMemberList(data, opts = {}) {
	//  会员列表
    return send({
        url: "/admin/baseUser/searchList.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getMemberDetail(data, opts = {}) {
	//  会员详情
    return send({
        url: "/admin/baseUser/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getCardList(data, opts = {}) {
	//  用户次卡年卡列表
    return send({
        url: "/admin/userCard/searchList.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getCouponList(data, opts = {}) {
	//  兑换券优惠券列表
    return send({
        url: "/admin/userCoupon/searchList.do",
        method: "POST",
        data,
        ...opts,
    });
}


export function disabledUser(data, opts = {}) {
	//  禁用用户
    return send({
        url: "/admin/baseUser/pullOffShelves.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function enabledUser(data, opts = {}) {
	//  解禁用户
    return send({
        url: "/admin/baseUser/pullOnShelves.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getOrderList(data, opts = {}) {
	//  影票订单 商品订单
    return send({
        url: "/admin/orderBase/searchList.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getCommissionList(data, opts = {}) {
	//  返佣列表
    return send({
        url: "/admin/distribution/orderList.do",
        method: "POST",
        data,
        ...opts,
    });
}


export function bindExchange(data, opts = {}) {
	//  绑定兑换券
    return send({
        url: "/admin/coupon/bindByUser.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function bindNumberCard(data, opts = {}) {
	//  绑定次卡
    return send({
        url: "/admin/cardVolume/bindNumberCard.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function bindYearCard(data, opts = {}) {
	//  绑定年卡
    return send({
        url: "/admin/cardVolume/bindYearCard.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function bindRechargeCard(data, opts = {}) {
	//  绑定影城储值卡
    return send({
        url: "/admin/cardVolume/bindRechargeCard.do",
        method: "POST",
        data,
        ...opts,
    });
}


export function bindMemberCard(data, opts = {}) {
	// 绑定影城会员卡
  return send({
    url: "/admin/card/bindEntityCard.do",
    method: "POST",
    data,
    ...opts,
  });
}