<template>
    <div>
        <h1>绑定影城会员卡</h1>
        <a-spin :spinning="loading">
            <a-form ref="form" name="form" :model="modelRef" :label-col="{ span: 6 }" :wrapper-col="{ span: 10 }" @finish="onSubmit">
                <a-form-item name="organizationId" label="影院组织" :rules="[{ required: true, message: '必选项不允许为空' }]">
                    <a-select placeholder="请选择" v-model:value="modelRef.organizationId" @change="(id) => getAllCinemaList(id)">
                        <a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item name="cinemaId" label="所属影院" :rules="[{ required: true, message: '必选项不允许为空' }]">
                    <a-select placeholder="请选择" v-model:value="modelRef.cinemaId" @change="getCouponList">
                        <a-select-option v-for="item in cinemaList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
                    </a-select>
                </a-form-item>
               
                <a-form-item label="会员手机号" name="phone" :rules="[{ required: true, message: '必填项不允许为空' }]">
                    <a-input v-model:value="modelRef.phone" placeholder="请输入会员手机号"></a-input>
                </a-form-item>

                <a-form-item label="影城会员卡号" name="cardNumber" :rules="[{ required: true, message: '必填项不允许为空' }]">
                    <a-input v-model:value="modelRef.cardNumber" placeholder="请输入影城会员卡号"></a-input>
                </a-form-item>

                <a-form-item label="影城会员卡密" name="password" :rules="[{ required: true, message: '必填项不允许为空' }]">
                    <a-input-password v-model:value="modelRef.password" placeholder="请输入影城会员卡密" />
                </a-form-item>

                <div style="text-align: center;">
                    <a-button type="primary" html-type="submit">确定</a-button>
                </div>
            </a-form>
        </a-spin>
    </div>
</template>

<script>
    import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
    import { getCardPolicyList } from '@/service/modules/coupon.js';
    import { bindMemberCard } from '@/service/modules/member.js';
    export default {
        data() {
            return {
                loading: false,
                organizationList: [],
                cinemaList: [],
                cardAllList: [],
                modelRef: {
                    // isDisabled: 0
                }
            }
        },
        created() {
            this.getOrganizationList();
        },
        methods: {
            onSubmit() {
                this.$confirm({
                    title: '提示',
                    content: '确定绑定影城会员卡至该用户吗？',
                    onOk: async () => {
                        this.loading = true;
                        try {
                            let ret = await bindMemberCard(this.modelRef);
                            this.loading = false;
                            if (ret.code === 200){
                                this.$message.success('绑定成功');
                                // this.modelRef = { isDisabled: 0 }
                            }
                        } catch(e) {
                            this.loading = false;
                        }
                    }
                })
            },
            async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.modelRef.cinemaId = undefined;
				this.cardPolicyAllList = [];
				this.modelRef.cardId = undefined;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaList = ret.data.list;
				}
			},
			async getCouponList(cinemaId) {
			    this.cardPolicyAllList = [];
				this.modelRef.cardId = undefined;
				let ret = await getCardPolicyList({
					page: 1,
					pageSize: 999999,
					type: 3,
					organizationId: this.modelRef.organizationId ? this.modelRef.organizationId : undefined,
					cinemaId: cinemaId ? cinemaId : undefined
				})
				if (ret.code === 200) {
					this.cardAllList = ret.data.list;
				}
			}
        }
    }
</script>

<style scoped>

</style>